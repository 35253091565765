import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Img from "gatsby-image"

export const query = graphql`
  query PostsByID($id: String) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        date(formatString: "YYYY MMMM Do")
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
const BlogDetails = ({ data }) => {
  const { frontmatter, body } = data.mdx
  return (
    <Layout>
      <div className="px-8 md:px-16 py-12 space-y-4 max-w-screen-xl mx-auto">
        <Img
          fluid={frontmatter.image.childImageSharp.fluid}
          className="mt-16 w-full"
        />

        <h1 className="text-3xl font-bold">{frontmatter.title}</h1>
        <div className="space-y-10">
          <MDXRenderer>{body}</MDXRenderer>
        </div>
      </div>
    </Layout>
  )
}

export default BlogDetails
